import { Link } from 'gatsby';
import React from 'react';
import algoliasearch from 'algoliasearch/lite';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardText,
  Col,
  Container,
  Row,
} from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import {
  InstantSearch,
  SearchBox,
  useInfiniteHits,
  RefinementList,
  useCurrentRefinements,
} from 'react-instantsearch-hooks-web';
// import SuggestingSearchBox from './SuggestingSearchBox';

import './satellite.scss'; // Algolia InstantSearch default styles
import './index.scss';

const searchClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_API_KEY,
);

const indexName = process.env.GATSBY_ALGOLIA_INDEX_COLLECTIONS;

const REFINEMENT_KEYS = [
  'collector',
  'commonName',
  'dimMetric',
  'material',
  'peopleCulture',
  'sciName',
  'placeMade',
  'collection',
  'indigenousName',
  'subgroup',
];

// const searchSuggestions = [
//   'Basket',
//   'Flute',
//   'Hmong',
//   'Lakota people',
//   'Skull',
//   'Spoon',
//   'Quilt',
//   'Ceremony',
// ];

function SearchResults(props) {
  const { hits, isLastPage, showMore } = useInfiniteHits(props);
  const fallbackImgSrc = 'https://images.ctfassets.net/x2t7lek2vf7h/O3U9fBTVElT6jEykAAaG4/65e8ed0fee616b72c27fb933ae141e9c/smm_logo_primary_2020.png?w=150';

  return (
    <>
      <Container className="hits-container">
        <Row>
          {hits.map((hit) => (
            <Col lg={4} md={4} sm={12}>
              <Link key={uuidv4()} to={`/${hit.slug}`} className="link-color link-style">
                <Card
                  className="hit-body"
                  key={hit.objectID}
                >
                  <img
                    src={hit.images?.[0] ? `${hit.images[0].file.url}?w=378` : fallbackImgSrc}
                    alt={hit.titleDisplay}
                  />
                  <CardBody>
                    <CardTitle tag="h3" className="title">{hit.titleDisplay}</CardTitle>
                    <CardSubtitle className="subtitle">{hit.catalogNumber}</CardSubtitle>
                    <CardText className="text">{hit.description?.description}</CardText>
                  </CardBody>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
      <br />
      <br />
      <div className="more-results">
        {isLastPage && <p>No more results</p>}
        {!isLastPage && <Button onClick={showMore} color="primary" size="lg">Show more</Button>}
      </div>
      <br />
    </>
  );
}

function CurrentRefinementList(props) {
  const { items, refine } = useCurrentRefinements(props);

  if (items.length === 0) {
    return null;
  }

  // Render the current refinements with a small x to remove them individually
  return (
    <div>
      {items.map((item) => (
        <div key={item.label}>
          <strong>
            {item.label}
            {': '}
          </strong>
          {item.refinements.map((refinement) => (
            <React.Fragment key={refinement.label}>
              <span>
                {refinement.label}
              </span>
              {' '}
              <Button onClick={() => refine(refinement)} color="link" outline size="sm">x</Button>
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  );
}

const Search = () => {
  // Create a RefinementList for each filter key in REFINEMENT_KEYS
  function renderRefinementList() {
    return REFINEMENT_KEYS.map((key) => (
      <RefinementList key={key} attribute={key} />
    ));
  }

  // TODO: Access current refinement list and adjust title accordingly
  // e.g., "Objects made with [material]" or "Objects made by [peopleCulture]"
  const searchTitle = () => {
    if (-1 > 0) {
      return `Objects where ${REFINEMENT_KEYS[0]} = TEMP`;
    }
    return 'Search our collections';
  };

  return (
    <div>
      <InstantSearch searchClient={searchClient} indexName={indexName} routing>
        { renderRefinementList() }
        <div style={{
          padding: '1%',
          width: '60%',
          margin: 'auto',
        }}
        >
          <h1>{searchTitle()}</h1>
          <SearchBox autoFocus placeholder="" />
          <CurrentRefinementList />
        </div>
        <br />
        <SearchResults />
      </InstantSearch>

    </div>
  );
};

export default Search;
